<template>
  <b-row>
    <b-col md="12" lg="12" sm="12">
      <KTCodePreview v-bind:title="''">
        <template v-slot:preview>
          <b-form ref="form" lazy-validation class="row">
            <!-- MAIN CONTENT SECTION -->
            <b-container class="bv-example-row">
              <b-row>
                <!-- LEFT CONTENT -->
                <b-col md="6" lg="6" sm="12">
                  <b-row>
                    <!-- PASSWORD -->
                    <b-col md="12">
                      <b-form-group
                        id="input-group-amount"
                        label-cols-md="4"
                        label="Mật khẩu cũ"
                        label-for="input-amount"
                        label-align-md="left"
                        required
                        class="required-control"
                      >
                        <b-form-input
                          id="input-amount"
                          class="text-left"
                          v-model="$v.mainModel.password.$model"
                          placeholder="Nhập mật khẩu cũ"
                          :state="validateItem('password')"
                          type="password"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-password-feedback">
                          Vui lòng nhập mật khẩu.
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        id="input-group-amount"
                        label-cols-md="4"
                        label="Mật khẩu mới"
                        label-for="input-newpassword"
                        label-align-md="left"
                        required
                        class="required-control"
                      >
                        <b-form-input
                          id="input-newpassword"
                          class="text-left"
                          v-model="$v.mainModel.newPassword.$model"
                          @keydown.space.prevent
                          placeholder="Nhập mật khẩu mới"
                          :state="validateItem('newPassword')"
                          type="password"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          id="input-newpassword-feedback"
                        >
                          Vui lòng nhập mật khẩu.
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        id="input-group-amount"
                        label-cols-md="4"
                        label="Nhập lại mật khẩu mới"
                        label-for="input-renewpassword"
                        label-align-md="left"
                        required
                        class="required-control"
                      >
                        <b-form-input
                          id="input-newpassword"
                          class="text-left"
                          v-model="$v.mainModel.reNewPassword.$model"
                          @keydown.space.prevent
                          placeholder="Nhập lại mật khẩu mới"
                          :state="validateItem('reNewPassword')"
                          type="password"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          id="input-renewpassword-feedback"
                        >
                          Thông tin không hợp lệ.
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-container>
          </b-form>
        </template>
        <template v-slot:foot>
          <!-- <b-col lg="4" class="pb-2"> -->
          <b-container class="bv-example-row">
            <b-row>
              <b-col md="6" xs="12" sm="12">
                <b-button
                  style="fontweight: 600; width: 70px; margin-right: 10px"
                  variant="primary"
                  size="sm"
                  type="submit"
                  @click="onSubmit"
                  >Lưu
                </b-button>
                <b-button
                  style="font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="$router.go(-1)"
                  >Hủy
                </b-button>
              </b-col>
            </b-row>
          </b-container>
        </template>
      </KTCodePreview>
    </b-col>
  </b-row>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { validationMixin } from 'vuelidate';
// import _ from 'lodash';
import { cmdUrl } from './../../../utils/apiUrl';
import ApiService from '@/core/services/api.service';
import { required, sameAs, minLength } from 'vuelidate/lib/validators';
import { getToastInstance } from './../../../utils/toastHelper';

export default {
  name: 'transaction',
  mixins: [validationMixin],
  components: {
    KTCodePreview
  },
  validations: {
    mainModel: {
      password: {
        required,
        minLength: minLength(6)
      },
      newPassword: {
        required,
        minLength: minLength(6)
      },
      reNewPassword: {
        required,
        minLength: minLength(6),
        sameAsPassword: sameAs('newPassword')
      }
    }
  },
  mounted() {
    this.loadBreadcum();
    this.fetchAccountants();
    this.fetchAllAccountants();
    this.fetchTransactionInfo();
    if (this.mainModelProp) {
      this.mainModel = Object.assign(this.mainModel, this.mainModelProp);
    }
  },
  data() {
    return {
      // Main model
      mainModel: {
        password: null,
        newPassword: null,
        reNewPassword: null
      }
    };
  },
  methods: {
    ...getToastInstance(),
    loadBreadcum: function() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: 'Thông tin cá nhân'
        },
        { title: 'Đổi mật khẩu' }
      ]);
    },
    validateItem(name) {
      const { $dirty, $error } = this.$v.mainModel[name];
      return $dirty ? !$error : null;
    },

    // SUBMIT DATA
    onSubmit: async function() {
      this.$v.mainModel.$touch();
      if (this.$v.mainModel.$anyError) {
        return;
      }

      ApiService.patch(cmdUrl.AuthUrl.changePwd, this.mainModel)
        .then(response => {
          const { status, message } = response.data;
          if (status === 1) {
            this.makeToastSuccess(message);
            //this.$router.go(-1);
            history.back();
          } else {
            this.makeToastFailure(message);
          }
        })
        .catch(err => {
          const message = err.response.data.message;
          this.makeToastFailure(message);
        });
    }
  }
};
</script>
